import React, { useEffect, useState } from 'react'
import { trackClick } from '../../analyticsUtils'
import MobileHighlight1 from '../../assets/images/home/animation/highlight-1-mobile.svg'
import Highlight1 from '../../assets/images/home/animation/highlight-1.svg'
import MobileHighlight2 from '../../assets/images/home/animation/highlight-2-mobile.svg'
import Highlight2 from '../../assets/images/home/animation/highlight-2.svg'
import MobileHighlight3 from '../../assets/images/home/animation/highlight-3-mobile.svg'
import Highlight3 from '../../assets/images/home/animation/highlight-3.svg'
import MobileHighlight4 from '../../assets/images/home/animation/highlight-4-mobile.svg'
import Highlight4 from '../../assets/images/home/animation/highlight-4.svg'
import MobileHighlight5 from '../../assets/images/home/animation/highlight-5-mobile.svg'
import Highlight5 from '../../assets/images/home/animation/highlight-5.svg'
import HomeHero from '../../assets/images/home/home-hero.svg'
import Block from '../primitives/Block'
import Box from '../primitives/Box'
import Stack from '../primitives/Stack'
import Br from '../system/Br'
import Button from '../system/Button'
import { DarkAnnouncementBadge } from '../system/DarkAnnouncementBadge'

export const homeHeroPaddingX = { xs: 20, sm: 64, md: 92 } as const

const desktopButtonMaxWidth = 320

const primaryButton = { text: 'Get a demo', href: '/demo/' }
const secondaryButton = {
  text: 'Start free trial',
  href: 'https://app.swarmia.com/signup/',
}

const HomeHeroBlock = () => {
  return (
    <Box
      backgroundPosition={{
        xs: 'right calc(50% + 425px) bottom -300px',
        sm: 'center bottom -50px',
        desktopMenu: 'center -50px',
      }}
      backgroundRepeat="no-repeat"
      backgroundImage={`url(${HomeHero})`}
      backgroundColor={{ xs: 'homeHeroBgMobile', desktopMenu: 'homeHeroBg' }}
      backgroundSize="1920px 920px"
      color="white"
      minHeight={{ xs: 0, desktopMenu: 880 }}
      // @ts-expect-error -- this works
      paddingTop={{ xs: 92, desktopMenu: 124 }}
      // @ts-expect-error -- this works
      paddingBottom={192}
      overflowX="hidden"
      position="relative"
      zIndex={0}
    >
      <Box
        // This frame prevents the animation from overflowing
        position="absolute"
        overflow="hidden"
        width="100%"
        height={720}
        top={0}
        display={{ xs: 'none', desktopMenu: 'block' }}
        zIndex={-1}
      >
        <Box
          // This frame keeps the animation at the exact location
          position="absolute"
          width="full"
          left="50%"
          top={0}
          height={720}
          transform="translateX(-50%)"
        >
          <Animation />
        </Box>
      </Box>
      <Block width="100%" paddingX={homeHeroPaddingX}>
        <Stack
          alignItems={{ xs: 'center', desktopMenu: 'flex-start' }}
          textAlign={{ xs: 'center', desktopMenu: 'left' }}
        >
          <Box paddingBottom={{ xs: 40, xsm: 48 }}>
            <DarkAnnouncementBadge
              text="Swarmia named a Leader in G2’s spring 2025 reports"
              href="https://www.g2.com/products/swarmia/"
            />
          </Box>
          <Box.h1
            maxWidth={{ xs: '100%', md: 790 }}
            fontFamily="FactorA"
            fontSize={{ xs: 40, xsm: 48, sm: 56, md: 56 }}
            fontWeight={700}
            lineHeight="110%"
            letterSpacing="-0.02em"
          >
            Rethink <Br display={{ xs: 'block', sm: 'none' }} /> developer
            <Br /> productivity <Br display={{ xs: 'block', sm: 'none' }} />
          </Box.h1>
          <Box
            paddingTop={{ xs: 16, xsm: 24 }}
            font="large"
            fontSize={{ xs: 18, sm: 20 }}
            maxWidth={{ xs: 380, sm: 480 }}
          >
            Build better software faster{' '}
            <Br display={{ xs: 'block', sm: 'none' }} /> with insights
            <Br /> that power your whole engineering organization.
          </Box>
          <Stack
            paddingTop={40}
            space={16}
            width="100%"
            alignItems={{ xs: 'center', desktopMenu: 'flex-start' }}
          >
            <Button
              onClick={trackClick('HomePage - Primary Button')}
              href={primaryButton.href}
              variant="primary"
              size="large"
              width="100%"
              maxWidth={{
                xs: '100%',
                xsm: desktopButtonMaxWidth,
              }}
              rightCaret
            >
              {primaryButton.text}
            </Button>
            <Button
              variant="secondaryWithBorder"
              onClick={trackClick('HomePage - Secondary Button')}
              href={secondaryButton.href}
              size="large"
              width="100%"
              maxWidth={{
                xs: '100%',
                xsm: desktopButtonMaxWidth,
              }}
              rightCaret
            >
              {secondaryButton.text}
            </Button>
          </Stack>
        </Stack>
      </Block>
      <Box
        paddingTop={{ xs: 48, sm: 92 }}
        display={{ xs: 'block', desktopMenu: 'none' }}
        paddingX={20}
      >
        <MobileAnimation />
      </Box>
    </Box>
  )
}

export default HomeHeroBlock

function Animation() {
  const [currentIndex, setIndex] = useState(0)
  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((currentIndex + 1) % 5)
    }, 4500)
    return () => clearInterval(intervalId)
  }, [currentIndex])

  const commonProps = {
    position: 'absolute',
    transition: 'all 0.5s ease-in-out',
  } as const
  return (
    <>
      <Box.img
        {...commonProps}
        left={{ xs: 842, lg: 802 }}
        top={{ xs: 80, lg: 106 }}
        src={Highlight1}
        opacity={currentIndex === 0 ? 1 : 0}
      />
      <Box.img
        {...commonProps}
        left={{ xs: 700, lg: 664 }}
        top={{ xs: 420, lg: 405 }}
        src={Highlight2}
        opacity={currentIndex === 1 ? 1 : 0}
      />
      <Box.img
        {...commonProps}
        left={{ xs: 860, lg: 782 }}
        top={{ xs: 150, lg: 182 }}
        src={Highlight3}
        opacity={currentIndex === 2 ? 1 : 0}
      />
      <Box.img
        {...commonProps}
        left={767}
        top={339}
        src={Highlight4}
        opacity={currentIndex === 3 ? 1 : 0}
      />
      <Box.img
        {...commonProps}
        left={{ xs: 860, lg: 745 }}
        top={{ xs: 80, lg: 133 }}
        src={Highlight5}
        opacity={currentIndex === 4 ? 1 : 0}
      />
    </>
  )
}

function MobileAnimation() {
  const [currentIndex, setIndex] = useState(0)
  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((currentIndex + 1) % 5)
    }, 4000)
    return () => clearInterval(intervalId)
  }, [currentIndex])

  const commonProps = {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    transition: 'all 0.5s ease-in-out',
    maxWidth: '100%',
  } as const
  return (
    <Box
      position="relative"
      maxHeight={318}
      css={`
        aspect-ratio: 380 / 316;
      `}
      marginX="auto"
    >
      <Box.img
        {...commonProps}
        src={MobileHighlight1}
        opacity={currentIndex === 0 ? 1 : 0}
      />
      <Box.img
        {...commonProps}
        src={MobileHighlight2}
        opacity={currentIndex === 1 ? 1 : 0}
      />
      <Box.img
        {...commonProps}
        src={MobileHighlight3}
        opacity={currentIndex === 2 ? 1 : 0}
      />
      <Box.img
        {...commonProps}
        src={MobileHighlight4}
        opacity={currentIndex === 3 ? 1 : 0}
      />
      <Box.img
        {...commonProps}
        src={MobileHighlight5}
        opacity={currentIndex === 4 ? 1 : 0}
      />
    </Box>
  )
}
