import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import heroImage from '../../assets/images/unblocked/hero.png'
import logoImage from '../../assets/images/unblocked/logo.svg'
import mobileHeroImage from '../../assets/images/unblocked/mobile-hero.png'
import { responsiveScale } from '../../styles/helpers'
import Block from '../primitives/Block'
import Box from '../primitives/Box'
import Stack from '../primitives/Stack'
import Br from '../system/Br'
import Button from '../system/Button'

export const query = graphql`
  {
    screenshot: file(relativePath: { eq: "images/unblocked-screenshot.png" }) {
      childImageSharp {
        gatsbyImageData(width: 770, height: 161, layout: FULL_WIDTH)
      }
    }
  }
`

export function UnblockedBlock() {
  const data = useStaticQuery(query)

  return (
    <Box
      paddingY={responsiveScale(48)}
      backgroundSize="cover"
      backgroundImage={{
        xs: `url('${mobileHeroImage}')`,
        sm: `url('${heroImage}')`,
      }}
      css={`
        background-color: #0c0f13;

        background-position: top center;
        background-repeat: no-repeat;
      `}
    >
      <Block
        paddingTop={responsiveScale(92)}
        paddingBottom={{ xs: 24, sm: 92 }}
      >
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          position="relative"
        >
          <Box.a
            href="https://www.unblocked.fm/"
            target="_blank"
            position="relative"
            zIndex={10}
            paddingX={24}
          >
            <Box.img src={logoImage} width={260} />
          </Box.a>
          <Box
            color="white"
            textAlign="center"
            paddingTop={responsiveScale(32)}
            paddingBottom={responsiveScale(48)}
            maxWidth={{ xs: 400, sm: 640 }}
            font="large"
          >
            Conversations with software leaders navigating <Br /> the challenges
            of scale, complexity, and growth.
          </Box>
          <Stack maxWidth={{ xs: 640, lg: 780 }} space={24} width="100%">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box
                font="h6"
                fontSize={16}
                textTransform="uppercase"
                color="white"
                opacity={0.5}
              >
                Latest Episode
              </Box>
              <Button
                href="https://www.unblocked.fm/"
                target="_blank"
                variant="leadLink"
                color="podcastLink"
                hoverProps={{ color: 'white' }}
                arrow
                css="margin-top: -3px;"
              >
                Explore more
              </Button>
            </Box>
            <Box.a
              href="https://www.unblocked.fm/episodes/kristen-foster-marks-depot/"
              borderRadius={8}
              css={`
                border: 4px solid white;
              `}
              width="100%"
              backgroundColor="white"
              target="_blank"
            >
              <GatsbyImage
                style={{
                  borderRadius: 4,
                  border: '1px solid #dcdcdc',
                  maxWidth: '100%',
                }}
                image={data.screenshot.childImageSharp.gatsbyImageData}
                alt="Latest Unblocked.fm podcast episode embed"
              />
            </Box.a>
          </Stack>
        </Box>
      </Block>
    </Box>
  )
}
