import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image'
import React, { ReactNode, useState } from 'react'
import AgreementsIcon from '../../assets/images/home/icons/agreements.svg'
import BubbleIcon from '../../assets/images/home/icons/bubble.svg'
import ChartIcon from '../../assets/images/home/icons/chart.svg'
import CogIcon from '../../assets/images/home/icons/cog.svg'
import CompassIcon from '../../assets/images/home/icons/compass.svg'
import DiamondIcon from '../../assets/images/home/icons/diamond-hand.svg'
import EyeIcon from '../../assets/images/home/icons/eye.svg'
import InitiativesIcon from '../../assets/images/home/icons/initiatives.svg'
import ManagerIcon from '../../assets/images/home/icons/manager.svg'
import MountainIcon from '../../assets/images/home/icons/mountain.svg'
import PatternIcon from '../../assets/images/home/icons/pattern.svg'
import StopwatchIcon from '../../assets/images/home/icons/stopwatch.svg'
import { responsiveScale } from '../../styles/helpers'
import Block from '../primitives/Block'
import Box from '../primitives/Box'
import Row from '../primitives/Row'
import Stack from '../primitives/Stack'
import Br from '../system/Br'
import Button from '../system/Button'
import { Quote, quotes } from './QuoteBlock'
import { SmallQuoteBlock } from './SmallQuoteBlock'

export function PersonasBlock() {
  const data = useStaticQuery(graphql`
    query {
      leaders: file(relativePath: { eq: "images/personas/leaders.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      managers: file(relativePath: { eq: "images/personas/managers.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      platform: file(relativePath: { eq: "images/personas/platform.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      developers: file(relativePath: { eq: "images/personas/developers.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  const tabs = [
    {
      key: '1',
      label: 'Engineering leaders',
      content: (
        <TabContent
          subtitle="Swarmia for engineering leaders"
          title={
            <>
              Take the guesswork out <Br /> of decision-making
            </>
          }
          bullets={[
            {
              icon: InitiativesIcon,
              text: (
                <>
                  Lead strategic initiatives with
                  <Br /> confidence and deliver value on time
                </>
              ),
            },
            {
              icon: DiamondIcon,
              text: (
                <>
                  Translate engineering work
                  <Br /> into business value
                </>
              ),
            },
            {
              icon: ManagerIcon,
              text: (
                <>
                  Empower managers and teams <Br /> to unblock the flow
                </>
              ),
            },
          ]}
          image={getImage(data.leaders)!}
          quote={quotes.alex5}
        />
      ),
    },
    {
      key: '2',
      label: 'Engineering managers',
      content: (
        <TabContent
          subtitle="Swarmia for engineering managers"
          title={
            <>
              Remove process blockers
              <Br /> and improve delivery
            </>
          }
          bullets={[
            {
              icon: CompassIcon,
              text: (
                <>
                  Regain focus and ship
                  <Br /> top priorities faster
                </>
              ),
            },
            {
              icon: EyeIcon,
              text: (
                <>
                  Get visibility into unplanned work, bugs,
                  <Br /> scope creep, and technical debt
                </>
              ),
            },
            {
              icon: PatternIcon,
              text: (
                <>
                  Identify team work anti-patterns
                  <Br /> and improve collaboration
                </>
              ),
            },
          ]}
          image={getImage(data.managers)!}
          quote={quotes.dan}
        />
      ),
    },
    {
      key: '3',
      label: 'Platform teams',
      content: (
        <TabContent
          subtitle="Swarmia for platform teams"
          title={
            <>
              Capture real-time data
              <Br /> on developer experience
            </>
          }
          bullets={[
            {
              icon: MountainIcon,
              text: (
                <>
                  Gain visibility into team-level
                  <Br /> development practices and bottlenecks
                </>
              ),
            },
            {
              icon: ChartIcon,
              text: (
                <>
                  Get system-level metrics on CI/CD,
                  <Br /> flaky tests, and technical debt
                </>
              ),
            },
            {
              icon: CogIcon,
              text: (
                <>
                  Automate engineering workflows
                  <Br /> and improve developer experience
                </>
              ),
            },
          ]}
          image={getImage(data.platform)!}
          quote={quotes.dave3}
        />
      ),
    },
    {
      key: '4',
      label: 'Developers',
      content: (
        <TabContent
          subtitle="Swarmia for developers"
          title={
            <>
              Get more focus time and
              <Br /> automate routine workflows
            </>
          }
          bullets={[
            {
              icon: StopwatchIcon,
              text: (
                <>
                  Cut down waiting time and get
                  <Br /> pull requests through faster
                </>
              ),
            },
            {
              icon: AgreementsIcon,
              text: (
                <>
                  Set working agreements to improve
                  <Br /> collaboration in the team
                </>
              ),
            },
            {
              icon: BubbleIcon,
              text: (
                <>
                  Have better conversations with
                  <Br /> your team and manager
                </>
              ),
            },
          ]}
          image={getImage(data.developers)!}
          quote={quotes.josep}
        />
      ),
    },
  ]
  const [activeTab, setActiveTab] = useState(tabs[0].key)

  return (
    <Block paddingTop={32} paddingBottom={48}>
      <Stack space={responsiveScale(48)}>
        <Box
          display="flex"
          justifyContent="center"
          position="relative"
          // @ts-expect-error this works
          marginX={{ xs: -20, md: 0 }}
        >
          <Box
            position="absolute"
            right={0}
            top={0}
            width={20}
            height="100%"
            css={`
              background: linear-gradient(
                270deg,
                rgba(255, 255, 255, 0.4) 0%,
                rgba(255, 255, 255, 0) 100%
              );
            `}
          />
          <Row
            space={{ xs: 8, sm: 12 }}
            overflowX="auto"
            paddingBottom={4}
            paddingX={{ xs: 20, md: 0 }}
            css={`
              scrollbar-width: none;

              &::-webkit-scrollbar {
                display: none;
              }
            `}
          >
            {tabs.map(tab => (
              <TabButton
                key={tab.key}
                onClick={() => setActiveTab(tab.key)}
                active={tab.key === activeTab}
              >
                {tab.label}
              </TabButton>
            ))}
          </Row>
        </Box>

        <Box>{tabs.find(tab => tab.key === activeTab)?.content ?? null}</Box>
      </Stack>
    </Block>
  )
}

function TabButton({
  children,
  active,
  onClick,
}: {
  children: ReactNode
  active: boolean
  onClick: () => void
}) {
  return (
    <Button
      fontSize={{ xs: 16, md: 18 }}
      variant="hoverHighlight"
      height={{ xs: 40, md: 50 }}
      backgroundColor={active ? 'blue' : 'white'}
      color={active ? 'white' : 'black700'}
      hoverProps={
        active
          ? { backgroundColor: 'blue', color: 'white' }
          : { color: 'black900' }
      }
      paddingX={32}
      borderRadius={9999}
      onClick={onClick}
    >
      {children}
    </Button>
  )
}

type BulletPoint = {
  icon: string
  text: ReactNode
}

function TabContent({
  subtitle,
  title,
  bullets,
  image,
  quote,
}: {
  subtitle: string
  title: ReactNode
  bullets: BulletPoint[]
  image: IGatsbyImageData
  quote: Quote
}) {
  return (
    <Row space={24} flexDirection={{ xs: 'column', md: 'row' }}>
      <Box
        flex={1}
        borderRadius={{ xs: 16, md: 32 }}
        padding={{ xs: 24, sm: 64 }}
        borderColor="black100"
      >
        <Box
          font="small"
          textTransform="uppercase"
          fontWeight={700}
          color="dataPurple"
          paddingBottom={24}
          lineHeight="20px"
          display={{ xs: 'none', sm: 'block' }}
        >
          {subtitle}
        </Box>
        <Box
          font="h3"
          paddingBottom={{ xs: 24, md: 32 }}
          fontSize={{ xs: 24, md: 32 }}
        >
          {title}
        </Box>
        <Stack space={24}>
          {bullets.map(bullet => (
            <Row key={bullet.icon} space={20} alignItems="center">
              <Box.img
                loading="lazy"
                width={32}
                height={32}
                src={bullet.icon}
              />
              <Box font="large" fontSize={{ xs: 16, md: 20 }} lineHeight="130%">
                {bullet.text}
              </Box>
            </Row>
          ))}
        </Stack>
      </Box>
      <Box flex={1}>
        <Stack space={{ xs: 24, md: 32 }}>
          <Box
            isolation="isolate"
            overflow="hidden"
            borderRadius={{ xs: 16, md: 24 }}
          >
            <GatsbyImage image={image} alt="" />
          </Box>
          <Box paddingLeft={32} paddingBottom={{ xs: 0, md: 12 }}>
            <SmallQuoteBlock quote={quote} />
          </Box>
        </Stack>
      </Box>
    </Row>
  )
}
